.MuiBackdrop-root {
  background: #4b4b4b !important;
}
.dialog-text {
  line-height: 1.3em;
}
.dialog-contactUs {
  .dialog-contactUs-title {
    h2 {
      font-size: 24px;
    }
  }
  .dialog-contactUs-content {
    .dialog-contactUs-content-info {
      .dialog-contactUs-content-info-item {
        margin-bottom: 5px;
        span {
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
    .dialog-contactUs-content-subtitle {
      // margin-top: 20px;
    }
    .dialog-contactUs-content-form {
      .dialog-contactUs-content-form-wrap {
        display: flex;
        flex-direction: column;
        .dialog-contactUs-content-form-wrap-disableTextarea {
          margin: 10px 0;
          height: auto;
          display: flex;
          align-items: flex-end;
          .dialog-contactUs-content-form-wrap-disableTextarea-textarea {
            height: 100%;
            width: 95%;
          }
          .dialog-contactUs-content-form-wrap-disableTextarea-form {
            height: 100%;
            border-bottom: 1px solid #000;
            width: 95%;
          }
          .dialog-contactUs-content-form-wrap-disableTextarea-icon {
            cursor: pointer;
            height: 20px;
            background: url("../../../assets/images/editIcon.png") no-repeat center 90% / contain;
            width: 5%;
            margin-bottom: 12px;
          }
        }
        .dialog-contactUs-content-form-email {
          max-width: 250px;
        }
      }
      .dialog-contactUs-content-form-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .dialog-contactUs-content-form-btns-close {
          color: #aaa;
          font-weight: 400;
          &:hover {
            color: #0097a7;
          }
        }
        .dialog-contactUs-content-form-btns-send {
          color: #0097a7;
          &:hover {
            color: #fff;
            background: #0097a7;
          }
        }
      }
    }
  }
}
.footer {
  background: #000;
  padding: 30px 20px;
  margin-top: auto;
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      width: 100%;
      li {
        //width: 25%;
        padding: 5px 10px;
        margin: 0 10px;
        text-align: center;
        @media screen and (max-width: 640px) {
          width: 50%;
          margin-bottom: 20px;
        }
        button {
          text-transform: none;
          padding: 0;
          font-weight: 400;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
    .footerBlock {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      justify-content: left;

      @media screen and (max-width: 620px) {
        flex-direction: column;
      }

      .socialInfo {
        display: flex;
        margin-right: 100px;

        @media screen and (max-width: 620px) {
          margin-right: 0;
        }

        img {
          max-width: 150px;
          max-height: 79px;
          margin: 0 20px;
        }
        a {
          color: #fff;
        }
      }
      .socialLinks {
        display: flex;

        @media screen and (max-width: 620px) {
          margin-top: 20px;
        }

        .socialLink {
          transition: all 0.5s;
          &:hover {
            filter: brightness(60%);
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
