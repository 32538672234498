.page6 {
  flex: 1;
  display: flex;
  .section1 {
    flex: 1;
    background: url("../../../assets/images/p6_bg.png") no-repeat center/cover;
    @media screen and (max-width: 500px) {
      padding: 0;
    }
    .page6Section1-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 100px 0 100px 0;
      @media screen and (max-width: 780px) {
        padding: 100px 20px 100px 20px;
        flex-direction: column;
      }
      .title {
        width: 100%;
        @media screen and (max-width: 780px) {
          margin-right: 0;
        }
        h1 {
          font-size: 36px;
          margin-bottom: 60px;
          line-height: 1.3em;
          padding-left: 50px;
          @media screen and (max-width: 780px) {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 500px) {
            padding-left: 0;
          }
        }
        .page6-paper {
          background: #fff;
          border-radius: 20px;
          box-shadow: -2px 2px 15px 5px rgba(0, 0, 0, 0.3);
          max-width: 650px;
          width: 100%;
          position: relative;
          padding: 20px 30px 100px;
          h2 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 40px;
          }
          p {
            font-size: 20px;
            max-width: 100%;
            line-height: 1.3em;
          }
          .p6s1-btn {
            background: #00bcd4;
            max-width: 300px;
            height: 36px;
            width: 100%;
            color: #fff;
            position: absolute;
            right: -20px;
            bottom: -18px;
          }
        }
      }
    }
  }
}
.dialog-doctorRegister {
  padding-top: 60px;
  .dialog-doctorRegister-title {
    font-size: 24px;
    text-align: center;
  }
  .dialog-doctorRegister-paper {
    box-shadow: 2px -2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 400px;
  }
  .dialog-doctorRegister-confirm {
    box-shadow: 2px -2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;
  }
  .dialog-doctorRegister-appBar {
    background: #0097a7 !important;
    .dialog-doctorRegister-toolBar {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
}
