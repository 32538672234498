.instruction {
  font-size: 30px;
  padding-top: 90px;
  padding-bottom: 60px;

  p {
    line-height: 1.5;
    margin-bottom: 10px;
    font-size: 16px;
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  img {
    max-width: 700px;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}
