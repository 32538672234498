.page2 {
  margin-top: 94px;
  margin-bottom: 50px;
  padding: 100px 0 50px;
  background: url("../../../assets/images/LoginLayout/p2.png") no-repeat right center/cover;
  h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .page2-simplePaper {
    max-width: 570px;
    position: relative;
    .page2-btn {
      display: flex;
      position: absolute;
      right: -50px;
      bottom: -20px;
      background: #00bcd4;
      color: #fff;
      max-width: 290px;
      width: 100%;
      min-height: 36px;
      @media screen and (max-width: 670px) {
        position: relative;
        right: auto;
        bottom: auto;
        margin: 30px auto;
      }
    }
  }
}
