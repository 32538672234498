.dialog-askSmileAssessment {
  padding-top: 60px;
  .dialog-askSmileAssessment-title {
    font-size: 24px;
    padding-left: 0 !important;
  }
  .dialog-askSmileAssessment-formWrap {
    max-width: 360px;
    margin-bottom: 50px;
  }
  .dialog-askSmileAssessment-simplePaper {
    max-width: 100%;
    border-radius: 3px;
    padding: 50px 30px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    .dialog-askSmileAssessment-simplePaper-text {
      font-weight: 700;
    }
    .dialog-askSmileAssessment-simplePaper-textSmall {
      font-weight: 400;
      margin-top: 30px;
      .pass-error {
        color: red;
        margin-top: 5px;
        text-align: center;
      }
    }
    .dialog-askSmileAssessment-simplePaper-textareaWrap {
      margin: 10px 0 30px;
      display: flex;
      align-items: flex-end;
      .dialog-askSmileAssessment-simplePaper-textarea {
        height: 100%;
        width: 95%;
      }
      .dialog-askSmileAssessment-simplePaper-textarea-icon {
        height: 20px;
        background: url("../../../../assets/images/editIcon.png") no-repeat center 90% / contain;
        width: 5%;
        margin-bottom: 8px;
      }
    }
    .dialog-askSmileAssessment-simplePaper-checkboxes {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      .dialog-askSmileAssessment-simplePaper-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        margin-bottom: 20px;
        @media screen and (max-width: 800px) {
          width: 33.3%;
        }
        @media screen and (max-width: 600px) {
          width: 50%;
        }
        @media screen and (max-width: 450px) {
          width: 100%;
        }
        img {
          max-width: 160px;
          min-height: 130px;
          object-fit: contain;
        }
        .dialog-askSmileAssessment-simplePaper-checkbox {
          margin-top: 10px;
          display: flex;
          align-items: center;
          label {
            margin-left: 10px;
          }
        }
      }
    }
    .dialog-askSmileAssessment-simplePaper-uploading {
      display: flex;
      margin-top: 30px;
      justify-content: center;
      .dialog-askSmileAssessment-simplePaper-uploadPic {
        max-width: 215px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .dialog-askSmileAssessment-simplePaper-uploadPic-img {
          cursor: pointer;
          position: relative;
          img {
            width: 100%;
          }
          &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: url("../../../../assets/images/ask_uploadIcon.png");
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.6);
            opacity: 0;
            transition: 0.5s;
          }
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        p {
          margin-top: 10px;
        }
      }
    }
    .askSmileAssessment-btn {
      display: block;
      max-width: 80px;
      background: #2196f3;
      margin: 30px auto 0;
    }
  }

  .container {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    height: 100%;
  }
  .dialog-askSmileAssessment-appBar {
    background: #0097a7 !important;
    .dialog-askSmileAssessment-toolBar {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
}
.ProtocolPhotos__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  width: 100%;
  margin: 0 18px 15px;
  & .item-title {
    text-align: center;
    color: #2d2d2d;
    font-size: 1.05rem;
    // font-weight: 700;
    height: 32px;
  }
  & .item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid #1b1464;
    // border-radius: 5px;
    max-width: 260px;
    width: 100%;
    height: 192px;
    margin: 5px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .react-file-drop-img-slider {
      width: 100px;
    }
    .item-content-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
      &:hover {
        .item-content-action {
          opacity: 1;
        }
      }
      & .item-content-action {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        font-size: 40px;
        color: rgba(255, 255, 255, 0.8);
        background: rgba(0, 0, 0, 0.4);
        opacity: 0;
        transition: 0.3s;
        & .addImage {
          font-size: 120px;
        }
        & .removeImage {
          font-size: 45px;
        }
      }
      & .item-content-image {
        display: block;
        width: 100%;
        height: 100%;
        // object-fit: scale-down;
        &.imgUpload {
          object-fit: cover;
        }
      }
      & .item-content-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
      }
      input[type="file"] {
        display: none;
      }
    }
  }
}
.dialog-doctorRegister-confirm {
  width: 600px;
  margin: 0 auto;
  .checkEmail {
    background-color: #ffffff;
    padding: 29px 27px 38px;
    text-align: center;
    position: relative;
    .checkEmail-close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #000;
      font-size: 1.5rem;
      cursor: pointer;
    }
    .checkEmail-title {
      color: black;
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    .checkEmail-line {
      display: block;
      max-width: 465px;
      height: 1px;
      background-color: #ed1c24;
      margin: 25px auto 30px;
    }
    .checkEmail-text {
      font-size: 15px;
      color: #2a2a2a;
      margin-bottom: 25px;
      max-width: 495px;
    }
    // .checkEmail-text-block {
    //   margin-bottom: 15px;
    .checkEmail-text {
      font-size: 15px;
      color: #2a2a2a;
      margin-bottom: 25px;
    }
    .checkSmile-text {
      font-size: 18px;
      color: #2a2a2a;
      margin-bottom: 25px;
    }
    .checkEmail-text-li {
      padding: 0 45px;
      text-align: left;
      margin-bottom: 8px;
    }
    // }
    .checkEmail-email {
      color: #0097a7;
      font-size: 29px;
      font-weight: 700;
      margin-bottom: 30px;
    }
  }
}
