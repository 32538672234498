.page5 {
  flex: 1;
  margin-top: 65px;
  background: url("../../../assets/images/LoginLayout/why_choose_bg.png") no-repeat 80% 25% / cover;
  margin-bottom: 100px;
  padding-bottom: 50px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 700px) {
    padding-bottom: 30px;
  }
  h1 {
    font-size: 36px;
    padding-top: 100px;
    padding-left: 50px;
    margin-bottom: 50px;
    @media screen and (max-width: 992px) {
      padding-top: 40px;
      padding-left: 0;
    }
  }
  .simplePaper {
    list-style: decimal inside;
    padding: 50px;
    position: relative;
    bottom: -150px;
    @media screen and (max-height: 1050px) {
      bottom: -25px;
    }
    @media screen and (max-width: 1900px) {
      bottom: -70px;
    }
    @media screen and (max-width: 1200px) {
      bottom: 0;
    }
    @media screen and (max-width: 500px) {
      padding: 30px 15px;
    }
    li {
      margin-bottom: 10px;
    }
  }
}
