.page1 {
  .btn {
    padding: 0 !important;
    margin: 0 !important;
  }
  .section1 {
    position: relative;
    z-index: 10;
    .title {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      left: 0;
      max-width: 870px;
      width: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(204, 204, 204, 0) 100%);
      z-index: 20;
      padding: 30px 0 30px 10vw;
      img {
        max-width: 420px;
        width: 100%;
        @media screen and (max-width: 850px) {
          max-width: 300px;
        }
        @media screen and (max-width: 500px) {
          max-width: 150px;
        }
      }
      h3 {
        font-weight: 400;
        font-size: 36px;
        @media screen and (max-width: 850px) {
          font-size: 20px;
        }
      }
      @media screen and (max-width: 850px) {
        top: 50%;
      }
    }
    video {
      width: 100%;
      height: auto;
      object-fit: contain;
      @media screen and (max-width: 500px) {
        margin-top: 50px;
      }
    }
    .section1-shadow {
      width: 100%;
      background: linear-gradient(to top, rgba(249, 249, 249, 1) 0%, rgba(249, 249, 249, 0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      @media screen and (max-width: 500px) {
        height: 50px !important;
        top: -50px;
      }
      @media screen and (max-width: 400px) {
        height: 80px !important;
        top: -80px;
      }
    }
  }
  .section2 {
    padding-bottom: 50px;
    position: relative;
    z-index: 20;
    // background: #f9f9f9;

    .simplePaper {
      margin: -250px auto 0;
      @media screen and (max-width: 992px) {
        margin-top: -50px;
      }
      @media screen and (max-width: 400px) {
        margin-top: 0;
      }
      h2 {
        font-size: 24px;
        text-align: center;
        font-weight: 700;
      }
      p {
        font-size: 24px;
        text-align: center;
        line-height: 1.3em;
      }
      .section2-btn {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 510px;
        width: 100%;
        min-height: 50px;
        color: #fff;
        background: #00bcd4;
      }
    }
    h2 {
      font-size: 24px;
      text-align: center;
      font-weight: 700;
      margin: 20px 0;
    }
    .section2-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      max-width: 850px;
      margin: -90px auto 0;
      @media screen and (max-width: 740px) {
        grid-template-columns: 1fr;
      }
      .section2-item {
        max-width: 390px;
        cursor: pointer;
        &:hover {
          .section2-item-pic {
            transform: translateY(50px);
          }
        }
        .section2-item-pic {
          position: relative;
          z-index: 10;
          max-width: 300px;
          margin: 0 auto;
          display: block;
          transform: translateY(100px);
          transition: all 0.5s;
        }
        .section2-item-simplePaper {
          padding-top: 60px;
          position: relative;
          z-index: 1;
          margin: 0;
          p {
            font-size: 14px;
            text-align: center;
            margin-bottom: 20px;
          }
          h3 {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
  .section3 {
    min-height: 650px;
    background: url("../../../assets/images/LoginLayout/p1s3.png") no-repeat center/cover;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1500px) {
      min-height: 500px;
    }
    .container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .section3-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .section3-list {
        max-width: 250px;
        list-style: disc inside;
        margin-bottom: 20px;
        span {
          display: block;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 1.5em;
        }
        li {
          padding-left: 20px;
          font-size: 18px;
          line-height: 1.5em;
        }
      }
      .section3-btn {
        max-width: 226px;
        min-height: 36px;
        margin: 0 !important;
        color: #fff;
      }
    }
  }
  .section4 {
    padding: 50px 0 100px;
    @media screen and (max-width: 770px) {
      padding: 50px 0;
    }

    &__instruction {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .btn {
        color: #fff;
        max-width: 370px;
        min-height: 50px;
      }
    }

    .section4-wrap {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 770px) {
        flex-direction: column;
        align-items: center;
      }
      .section4-item {
        display: block;
        max-width: 305px;
        position: relative;
        @media screen and (max-width: 770px) {
          margin-bottom: 30px;
        }
        &:hover {
          img {
            transform: translateY(-30px);
          }
        }
        img {
          width: 100%;
          transition: all 0.4s;
        }
        .section4-btn {
          max-width: 210px;
          width: 100%;
          min-height: 50px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .section4-btn-1 {
          background: #1e88e5;
        }
        .section4-btn-3 {
          background: #0097a7;
        }
      }
    }
  }
}
.btn {
  background: #00bcd4;
  width: 100%;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
  &:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0);
  }
}
.simplePaper {
  background: #fff;
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: -2px 2px 15px 5px rgba(0, 0, 0, 0.3);
  max-width: 650px;
  width: 100%;
  position: relative;
}
