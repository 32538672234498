.checkEmail {
  background-color: #ffffff;
  padding: 29px 27px 38px;
  text-align: center;
  position: relative;
  .checkEmail-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .checkEmail-title {
    color: black;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .checkEmail-line {
    display: block;
    max-width: 465px;
    height: 1px;
    background-color: #ed1c24;
    margin: 25px auto 30px;
  }
  .checkEmail-text {
    font-size: 15px;
    color: #2a2a2a;
    margin-bottom: 25px;
    max-width: 495px;
  }
  .checkEmail-text-block {
    margin-bottom: 15px;
    .checkEmail-text {
      font-size: 15px;
      color: #2a2a2a;
      margin-bottom: 25px;
    }
    .checkEmail-text-li {
      padding: 0 45px;
      text-align: left;
      margin-bottom: 8px;
    }
  }
  .checkEmail-email {
    color: #0097a7;
    font-size: 29px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
