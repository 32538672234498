#fileLoader
  display: flex
  flex-direction: column
  position: absolute
  top: 60px
  left: 50%
  transform: translateX(-50%)
  padding: 8px 16px
  width: 100%
  max-width: 600px
  background: rgba(255, 255, 255, 0.8)
  border: 1px solid #ddd
  border-radius: 4px
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)
  z-index: 99999999
  .fileLoader-item
    display: flex
    flex-direction: column
    width: 100%
    margin: 4px 0
    .fileLoader-item-name
      display: block
      margin-top: 8px
      color: #333
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

