.signInForm {
  .signInForm-btn {
    text-transform: none;
    background: #00acc1;
    border-radius: 20px;
    display: flex;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.3);
    margin: 20px auto 0;
    &:hover {
      background: #00acc1;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }
  }
  .signInForm-btn-forgot {
    padding: 15px;
    cursor: pointer;
    color: #006ce5;
    text-decoration: underline;
  }
}
