.page4 {
  padding: 100px 0 100px;
  background: #f9f9f9;
  .section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
    .title {
      margin-right: 50px;
      @media screen and (max-width: 700px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
      h1 {
        font-size: 36px;
        margin-bottom: 30px;
      }
      .page4-section1-simplePaper {
        padding: 50px 60px 50px 30px;
        p {
          margin-bottom: 5px;
        }
      }
    }
    img {
      max-width: 320px;
      width: 100%;
    }
  }
  .section2 {
    h2 {
      font-size: 24px;
      font-weight: 700;
      padding-left: 50px;
      margin-bottom: 20px;
      @media screen and (max-width: 650px) {
        text-align: center;
        padding-left: 0;
        padding-top: 30px;
      }
    }
    .page4-section2-subtitle {
      padding-left: 50px;
      @media screen and (max-width: 650px) {
        text-align: center;
        padding-left: 0;
      }
    }
    .section2-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;
      grid-gap: 20px;
      margin: 30px 0;
      @media screen and (max-width: 1365px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
      .section2-item {
        max-width: 280px;
        border-radius: 19px;
        overflow: hidden;
        box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);
        .section2-item-pic {
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 205px;
          &4 {
            align-items: flex-start;
          }
          img {
            width: 100%;
            max-height: 205px;
            object-fit: contain;
          }
          .section2-item-pic-img1 {
            max-width: 230px;
          }
          .section2-item-pic-img2 {
            max-width: 210px;
          }
          .section2-item-pic-img48 {
            max-width: 150px;
          }
        }
        .section2-item-text {
          min-height: 135px;
          height: 100%;
          padding: 20px 30px;
          background: #fff;
          color: #fff;
          &_black {
            color: #000;
          }
          h6 {
            font-size: 18px;
            margin-bottom: 15px;
          }
          &_blue {
            background: #2196f3;
          }
          &_orange {
            background: #00d4b6;
          }
          &_green {
            background: #00acc1;
          }
        }
      }
    }
  }
}
