.loginLayout {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3em;
  color: #000;
  outline: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3em;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    border-radius: 12px;
  }
  a {
    text-decoration: none;
    &:visited {
      color: unset;
    }
  }
  .container {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    height: 100%;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    border-radius: 14px;
    font-weight: 700;
    color: #fff;
    transition: 0.3s;
  }
  // MuiPaper-root
  // MuiPaper-elevation24
  // MuiDialog-paper
  // MuiDialog-paperScrollPaper
  // MuiDialog-paperWidthSm

  // MuiPaper-rounded
}
