.page3 {
  padding: 0 0 50px;
  @media screen and (max-width: 500px) {
    padding: 20px 0 0;
  }
  .section1 {
    padding: 150px 0;
    background: url("../../../assets/images/LoginLayout/how_work_bg1.png") no-repeat center/cover;
    .page3Section1-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 50px 50px;
      @media screen and (max-width: 992px) {
        padding: 0 0 50px 0;
      }
      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
      .title {
        padding-left: 0;
        @media screen and (max-width: 650px) {
          margin-bottom: 30px;
        }
        h1 {
          font-size: 36px;
          margin-bottom: 30px;
        }
        .simplePaper {
          p {
            max-width: 450px;
            text-align: justify;
            &:first-child {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .section2 {
    .page3-section2-wrap {
      margin-top: -150px;
      position: relative;
      .page3-section2-simplePaper {
        display: flex;
        justify-content: space-between;
        margin: 50px 0;
        padding: 80px 50px;
        max-width: 100%;
        position: relative;
        z-index: 10;
        @media screen and (max-width: 650px) {
          flex-direction: column;
          align-items: center;
        }
        img {
          width: 20%;
          @media screen and (max-width: 650px) {
            width: 100%;
            max-width: 250px;
          }
        }
        .page3-section2-subtitle {
          display: none;
          margin: 5px 0 40px;
          font-size: 18px;
          @media screen and (max-width: 650px) {
            display: block;
          }
        }
      }
      .page3-section2-simplePaper-desktopSubtitle {
        background: #00acc1;
        border-radius: 12px;
        padding: 70px 20px 30px;
        box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
        position: absolute;
        bottom: -120px;
        left: 50%;
        transform: translateX(-50%);
        width: 103%;
        z-index: 1;
        display: block;
        @media screen and (max-width: 650px) {
          display: none;
        }
        .page3-section2-simplePaper-desktopSubtitle-text {
          display: flex;
          p {
            width: 20%;
            margin-top: 0;
          }
        }
        p {
          text-align: center;
          color: #fff;
          margin-top: 30px;
          font-size: 18px;
        }
      }
    }
    .page3-section2-wrap2 {
      margin: 150px 0 150px;
      display: flex;
      justify-content: center;
      position: relative;
      @media screen and (max-width: 500px) {
        margin-bottom: 200px;
      }
      .page3-section2-simplePaper2 {
        padding: 30px 50px;
        max-width: 850px;
        position: relative;
        z-index: 10;
        .section2-paper-imgs2 {
          display: flex;
          justify-content: center;
          margin: 50px 0;
          @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: center;
            margin: 20px 0;
          }
          .section2-paper-imgs2-inner {
            display: flex;
            flex-direction: column;
            margin: 0 30px;
            @media screen and (max-width: 700px) {
              align-items: center;
              img {
                &:last-child {
                  transform: rotate(90deg);
                  max-width: 50px;
                  display: block;
                  margin: 30px 0;
                }
              }
            }
          }
        }
      }
      .page3-section2-simplePaper-desktopSubtitle2 {
        background: #00d4b6;
        border-radius: 12px;
        text-align: center;
        color: #fff;
        position: absolute;
        bottom: -80px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 900px;
        width: 100%;
        padding: 110px 30px 20px;
        font-size: 18px;
        line-height: 1.3em;
        z-index: 1;
        @media screen and (max-width: 740px) {
          bottom: -110px;
        }
        @media screen and (max-width: 500px) {
          bottom: -150px;
        }
        @media screen and (max-width: 400px) {
          bottom: -180px;
        }
      }
    }
    .page3-section2-video {
      max-width: 950px;
      margin: 0 auto;
      display: block;
      width: 100%;
      outline: none;
      box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
      border-radius: 12px;
      &[poster] {
        object-fit: cover;
      }
    }
    .page3-section2-wrap3 {
      margin-top: 50px;
      position: relative;
      padding-bottom: 100px;
      .page3-section2-simplePaper3 {
        margin: 0 auto;
        max-width: 850px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 30px;
        position: relative;
        z-index: 10;
        img {
          max-width: 450px;
          width: 100%;
        }
      }
      .page3-section2-simplePaper-desktopSubtitle3 {
        background: #039be5;
        border-radius: 12px;
        text-align: center;
        color: #fff;
        max-width: 900px;
        width: 100%;
        padding: 120px 30px 20px;
        font-size: 18px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }
  }
}
